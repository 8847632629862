import { template as template_8219baf440754100b380d2a968a5feef } from "@ember/template-compiler";
const FKControlMenuContainer = template_8219baf440754100b380d2a968a5feef(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
