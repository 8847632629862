import { template as template_4d64ecc0b585433c993b3adf43f91d23 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_4d64ecc0b585433c993b3adf43f91d23(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
