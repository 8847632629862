import { template as template_8bb72cc23c004b989e709256537607f3 } from "@ember/template-compiler";
const WelcomeHeader = template_8bb72cc23c004b989e709256537607f3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
