import { template as template_2ad1ba3c9b8844c3820d36ba1ebbae6b } from "@ember/template-compiler";
const FKText = template_2ad1ba3c9b8844c3820d36ba1ebbae6b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
