import { template as template_878b5ad3bb69465e81b4f4c31d6977ca } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_878b5ad3bb69465e81b4f4c31d6977ca(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
