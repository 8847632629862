import { template as template_0f8f129ea2f84a25874a2e8d25924b95 } from "@ember/template-compiler";
const FKLabel = template_0f8f129ea2f84a25874a2e8d25924b95(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
